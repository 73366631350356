// color variants
@import 'themes-vars.module.scss';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}
:focus-visible {
  outline: none;
}


body {
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: 'MADE TOMMY', sans-serif;
  background: #F2EEE3 !important;

}

@media (max-width: 600px) {
  body {
    overflow-y: auto;
  }
}


/* .leaflet-control-layers-toggle {
  width: auto;
  background-color: rgb(212, 215, 255);
  padding: 4px;
  border: 2px solid transparent;
  background-position: 3px 50%;
  padding: 3px;
  padding-left: 36px;
  text-decoration: none;
  line-height: 36px;
} */

// Apply the custom styling to the LayersControl Overlay
/* .custom-overlay {
  height: 200px; // Adjust the height as needed
  background-color: #ffffff; // Add any other styles you want
} */


// ==============================|| PERFECT SCROLLBAR ||============================== //

.marker-cluster-small, .marker-cluster-medium, .marker-cluster-large {
  background-color: #306932 !important;
}

.marker-cluster-small div, .marker-cluster-medium div, .marker-cluster-large div {
  background-color: #FCF9F3 !important;
  color: #344933;
}

.custom-popup {
  z-index: 1101;
  border-radius: none !important;
  background-color: #F2EEE3 !important;
  border-radius: 12px;
}

.leaflet-popup {
  border-radius: none;
}


.leaflet-bottom {
  &.leaflet-left {
    // Styles for .leaflet-bottom.leaflet-left
    // For example, add padding and a background color
    
  }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #F2EEE3 !important;
  color: #333;
  border: 1px solid #164A1A;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.tile-layer-class {
  background-repeat: no-repeat;
  background-size: cover;
}

.scrollbar-container {
  .ps__rail-y {

    &:hover>.ps__thumb-y,
    &:focus>.ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
  &.ps--active-y>.ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y>.ps__rail-y,
  &.ps--scrolling-x>.ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {

  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {

  0%,
  50%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }
}

/* #search-card:hover #search-card-overlay {
  background-color: rgba(0, 0, 255, 0.4); // Change the background color on hover
}
 */
/* Track */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #D8D5CC;
    /* Change this to the background color you want */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919397;
  /* Change this to the scrollbar handle color */
}

::-webkit-scrollbar-thumb:hover {
  background: #818286;
  /* Change the color on hover */
}


.leaflet-bottom.leaflet-right {

  .leaflet-control-attribution {
    max-width: 35vw;
  }
  .leaflet-control-zoom {
    margin-right: 15px;
    &.leaflet-bar {

      // Set background color for the zoom control bar
      background-color: #F9F5EB;//#F2EEE3;
      border: 2px solid #D8D5CC;
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
      border-bottom-left-radius: 50px;
      box-shadow: 2px 12px 32px 2px #1D1D1B0D;

      a {
        span {
          display: none;
        }

        // Style zoom in button
        &.leaflet-control-zoom-in {
          color: #164A1A;
          background-color: transparent;
          width: 44px;
          height: 53px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-right-radius: 24px;
          border-top-left-radius: 24px;
          background-image: url('../images/icons/Plus.svg');
          box-shadow: 2px 12px 32px 2px #1D1D1B0D;


        }

        // Style zoom out button
        &.leaflet-control-zoom-out {
          color: #164A1A;
          background-color: #F9F5EB;
          width: 44px;
          height: 53px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 50px;
          border-bottom-left-radius: 50px;
          background-image: url('../images/icons/Minus.svg');
          box-shadow: 2px 12px 32px 2px #1D1D1B0D;


          &.leaflet-disabled {
            color: #888; // Set color for disabled zoom out button
          }
        }
      }
    }
  }

  .leaflet-control-attribution {
    &.leaflet-control {
      // Add styles for attribution control if needed

      a {
        // Style the attribution link
        color: #4C7BE1; // Set color for the attribution link
        text-decoration: none; // Remove underline from the link

        svg {
          // Add styles for the SVG icon within the attribution link if needed
        }
      }

      span {
        // Add styles for the separator (|) between the attribution link and Tiles text if needed
        margin: 0 5px;
      }
    }
  }
}

.leaflet-disabled {
  background-color: #F2EEE3!important;
}


.attribution-toggle-btn {
  position: absolute;
  bottom: 0;
  width: 2rem;
  height: 2.1rem;
  z-index: 999;
  background: #f9f5eb;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.3rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  color: #164a1a;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.message-mobile {
  background: #EAFFD8;
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #164A1A;
  display: flex;
}

.leaflet-control-attribution {
  padding: 5px;
  padding-right: 0px!important;
  border-radius: 3px;
  display: flex;
  place-items: center;
  min-width: 48px;
  margin-right: 8px!important;
  max-width: 27vw!important;
  border: 2px solid rgb(216, 213, 204);
}

.leaflet-control-attribution.collapsed-btn {
  width: 40px;
  min-width: 40px;
  justify-content: center;
  background: #f9f5eb!important;
  border-radius: 50%;
  padding-right: 5px!important;
}

.leaflet-control-attribution-text {
  padding-right: 5px;
}

.leaflet-control-attribution-toggle {
  background: #f9f5eb!important;
  display: block;
  text-align: center;
  cursor: pointer;
  color: #16491a!important;
  line-height: 1;
  padding: 4px;
  padding-top: 6px;
  border-radius: 50%;
  align-self: stretch;
  align-content: center;
}

.leaflet-control-attribution-toggle.open {
  border-radius: 0;
}

.leaflet-control-attribution-text.collapsed {
  display: none;
  padding-right: 0;
}


@media (max-width: 1024px) {
  
  .leaflet-bottom.leaflet-right {
    margin-bottom: 0.5rem;
  }
  .leaflet-control-attribution {
    max-width: 70vw!important;
  }
}

/* ============ APEX CHARTS ============== */
.apexcharts-tooltip {
  max-width: 300px !important;  /* Set your desired width */
  word-wrap: break-word !important;  /* Ensure words break properly */
  white-space: normal !important;  /* Allow text to wrap */
  overflow-wrap: break-word; /* Handle word breaking */
}

.apexcharts-tooltip-marker {
  width: 10px !important;  /* Set desired width */
  height: 10px !important;  /* Set desired height */
  border-radius: 50%;  /* Ensure it's a circle */
  padding: 5px;
  display: inline-block; /* Ensure it aligns correctly */
  vertical-align: middle; /* Align it vertically in the middle */
}

.apexcharts-legend-marker {
  height: 12px!important;
  width: 12px!important;
  border-radius: 12px!important;
}

.atlwdg-trigger {
  z-index: 999;
} 